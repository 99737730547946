<template lang="pug">
.avatar
	img(:src="`https://www.gravatar.com/avatar/${hashedUser}?d=mp&s=50`")
</template>
<script setup>
const { data } = useAuth();
import md5 from "md5";
const hashedUser = md5(data.value.username);
</script>
<style lang="scss">
.avatar {
  img {
    border: rempx(2) solid #fff;
    border-radius: rempx(120);
  }
  &:hover {
    img {
      border-color: $primary;
    }
  }
}
</style>
